import React from "react";
import { Layout, Row, Col, Collapse } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import GuideSider from "../../components/guide/guide-sider";

import css from "../../styles/guide.css";

const Panel = Collapse.Panel;

class TeamHelp extends React.Component {
  render() {
    return (
      <div>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content="DayViewer Planner Guide - Team shows how to setup the team feature and get the team calendar and scheduler working for your business or team."
            />
            <title>DayViewer Online Planner Guide - Team</title>
          </Helmet>
          <Header />
          <Layout style={{ minHeight: "100vh" }}>
            <GuideSider />
            <Layout className="guide-body">
              <h1>DayViewer Team Rooms</h1>
              <Row>
                <Col>
                  <Collapse>
                    <Panel header="How to create a Team Room" key="1">
                      <p>
                        DayViewer Team Rooms are Shareable version of DayViewer
                        where team members can work and schedule around a single
                        team calendar.
                      </p>
                      <p>
                        By working together on one calendar planner, it's easier
                        to stay synchronised. DayViewer Team Rooms could be used
                        as a simple project management system for any small to
                        medium project, or use as a job scheduling solution for
                        staff and employees.
                      </p>

                      <p>
                        Team Rooms may be opened, simply by clicking on your
                        avatar and in the menu it will show "Create A Team",
                        select and type in a team name.
                      </p>

                      <p>
                        More than one Team Room may be created within any
                        DayViewer account, for example if you have different
                        departments, or different branches - simply create a
                        room for each entity as required.
                      </p>

                      <p>
                        Also note that you may be part of someone else's Team
                        Room as a member therefore that team name will also be
                        listed in your Team Room list.
                      </p>
                    </Panel>
                    <Panel header="Add Members" key="2">
                      <p>
                        To add team members go to your Team Management area.
                        This is located inside your team in Settings > Team
                        Management. Here you add the Names &amp; Email Addresses
                        of the member you wish to invite to collaborate with.
                      </p>
                      <p>
                        Inform the added members to click a link they received
                        in their email to sign up to DayViewer and Join the Team
                        Room. Every member would access the Team room in the
                        same way (by clicking the Team Symbol in the header and
                        selecting the team name.
                      </p>
                      <p>
                        Note that you can be members of, and create multiple
                        teams. Also note that changing the number of members in
                        your team will adjust the price of the team room
                        accordingly. You would be billed the new price in the
                        next billing cycle.
                      </p>
                      <span>
                        <img
                          src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/usecases/create-team.png"
                          className="homepage-image"
                          alt="guide -Add Team Members"
                          title="Add Team Members"
                        />
                      </span>
                    </Panel>
                    <Panel header="Team Room Features" key="3">
                      Assign Tasks
                      <p>
                        From within the task entry form, team members with an
                        authority role(Managers, Owners and Admins) can select
                        other team members to assign the task to.
                      </p>
                      <p>Simply select member as shown in the image</p>
                      <span>
                        <img
                          src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/guideimages/guide-team-assign-task.png"
                          className="homepage-image"
                          alt="guide - Assign Team Task"
                          title="Assign Team Task"
                        />
                      </span>
                    </Panel>
                    <Panel header="Team Member Roles" key="4">
                      Member Roles
                      <p>
                        You can set member roles. This allows certain actions
                        only for that particular team member.
                      </p>
                      <p>Roles</p>
                      <span>
                        <ul>
                          <li>
                            Owner - This is the person that creates the team -
                            the owner also is the person charged for the team
                            room. Owners have all privelidges (Billing, Create,
                            Read, Update, Delete, Comment, Settings, Add
                            Members, Assign Tasks )
                          </li>
                          <li>
                            Admin - Admins have all editable privelidges and can
                            also Add/Edit members (Create, Read, Update, Delete,
                            Comment, Settings, Add Members, Assign Tasks )
                          </li>
                          <li>
                            Managers - Managers have all editable entries
                            privelidges (Create, Read, Update, Delete, Comment,
                            Assign Tasks )
                          </li>
                          <li>
                            Staff - Staff cannot delete entries(Create, Read,
                            Update, Comment)
                          </li>
                          <li>
                            Guest - Guests can read only and make comments on
                            entries.
                          </li>
                        </ul>
                      </span>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Layout>
          </Layout>
          <CommonFooter />
        </Layout>
      </div>
    );
  }
}

export default TeamHelp;
